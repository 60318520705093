.confirmations .frame-3 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 24px;
  padding: 0px 24px 0px 0px;
  position: relative;
}
.confirmations .frame-5 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 24px;
  overflow-y: scroll;
  padding: 0px 0px 40px;
  position: relative;
  width: 100%;
  z-index: 0;
}

.confirmations .frame-6 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 20px;
  position: relative;
  width: 100%;
}

.confirmations .transactions-table {
  align-items: flex-start;
  background-color: var(--collection-1-black);
  border: 1px solid;
  border-color: #ffffff33;
  border-radius: var(--shape-corner-large);
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: var(--size-space-400);
  overflow: hidden;
  padding: 24px;
  position: relative;
  height: 500px;
  margin-left: 25px;
  margin-top: 50px;
}

.confirmations .mini-potfolio-card-2 {
  align-items: flex-start;
  align-self: stretch;
  background-color: #181818;
  border-radius: var(--shape-corner-small);
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: var(--tokens-spacing-spacing-md);
  margin-left: -1px;
  margin-right: -1px;
  overflow-y: scroll;
  padding: 16px;
  position: relative;
  /* width: 100%; */
  margin-left: 25px;
}

.confirmations .frame-18 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  position: relative;
  width: 100%;
  height: 55px;
}

.confirmations .text-wrapper-7 {
  color: #cac4d0;
  font-family: "Roboto", Helvetica;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0.15px;
  line-height: 24px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.confirmations .frame-23 {
  align-items: center;
  align-self: stretch;
  border-radius: var(--tokens-radius-radius-rounded);
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: var(--tokens-spacing-spacing-xs);
  overflow: hidden;
  overflow-y: scroll;
  position: relative;
  width: 100%;
}

.confirmations .overlap-wrapper {
  background-color: #0e0f11;
  height: 1040px;
}

.confirmations .overlap {
  background-image: url(../../../public/image-17.png);
  background-position: 50% 50%;
  background-size: cover;
}

.confirmations .code-button {
  width: 150px;
  margin-bottom: 20px;
  margin-top: 17px;
  float: right;
}

/* MyTable.css */
.rs-table-row-header {
  border-radius: 5px 5px 0px 0px;
}

.rs-table-row-header .rs-table-cell {
  background-color: #000000 !important; /* Change header background color */
  color: #eeeeee; /* Change header text color */
  font-size: 14px;
}

.rs-table-body-row .rs-table-cell {
  background-color: #fff; /* Change row background color */
  color: #ffffff; /* Change row text color */
  border-bottom: 1px solid #ddd; /* Add border to rows */
}

.rs-table-row:nth-child(odd) .rs-table-cell {
  background-color: #1c1c1c;
}

.rs-table-row:nth-child(even) .rs-table-cell {
  background-color: #181818;
}

.rs-table-cell {
  color: #fff;
  border: 0px;
}

.rs-table-row:not(.rs-table-row-rowspan) {
  border: 0px;
}